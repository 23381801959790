<template>
  <div class="view">
    <!-- imgList: {{ imgList }} -->
    <div class="top">
      <img ref="main_image" :src="previewImg" crossOrigin="Anonymous" @mousemove="preview" @mouseenter="previewStart" @mouseleave="previewEnd" />
      <div ref="scan_preview" class="scan_preview"></div>
      <div ref="enlarge_div" class="enlarge">
        <img ref="enlarge" :src="previewImg" />
      </div>
    </div>
    <div class="bottom">
      <div :class="page == 1 ? 'btn_disable' : 'btn'" @click="prev">
        <i class="iconfont icon-xiangzuo1"></i>
      </div>
      <div class="scroll">
        <div class="image_list" :class="page == 1 ? 'page1' : 'page2'" :style="{ width: imgListWith }">
          <img
            v-for="(image, index) of imageList"
            :src="JLTools.formatUrl(image.path)"
            :key="index"
            :class="{ xuan: imageIndex == index }"
            @click="clickHandler(index)"
            :imageIndex="imageIndex"
          />
        </div>
      </div>
      <div :class="page == 2 || imageList.length < 5 ? 'btn_disable' : 'btn'" @click="next">
        <i class="iconfont icon-xiangyou1"></i>
      </div>
    </div>
  </div>
</template>

<script>
  import JLTools from '@/util/JLTools'
  export default {
    name: 'ImageView',
    props: {
      imgList: {
        type: [Function, Object],
        default() {
          return []
        }
      },
      curImage: {
        type: String,
        default: ''
      }
    },
    data() {
      return {
        imageIndex: 0,
        imageList: [
          {
            path: 'https://static-01.daraz.pk/p/4df6f157f9137287a75a09bfbc35c31f.jpg_1200x1200q100.jpg_.webp',
            thumb: 'https://static-01.daraz.pk/p/4df6f157f9137287a75a09bfbc35c31f.jpg_80x80q80.jpg_.webp'
          },
          {
            path: 'https://static-01.daraz.pk/p/7c0726dcecfc35a4455a0f19b5a8b58e.jpg_400x400q80.jpg_.webp',
            thumb: 'https://static-01.daraz.pk/p/7c0726dcecfc35a4455a0f19b5a8b58e.jpg_80x80q80.jpg_.webp'
          },
          {
            path: 'https://static-01.daraz.pk/p/df9e2070e222e6a93e6a606780240845.jpg_400x400q80.jpg_.webp',
            thumb: 'https://static-01.daraz.pk/p/df9e2070e222e6a93e6a606780240845.jpg_80x80q80.jpg_.webp'
          },
          {
            path: 'https://static-01.daraz.pk/p/8460c322c7d4a60165e9322d324fef49.jpg_400x400q80.jpg_.webp',
            thumb: 'https://static-01.daraz.pk/p/8460c322c7d4a60165e9322d324fef49.jpg_80x80q80.jpg_.webp'
          },
          {
            path: 'https://static-01.daraz.pk/p/a18e0b4b4403fa5389267fe9343f7473.jpg_400x400q80.jpg_.webp',
            thumb: 'https://static-01.daraz.pk/p/a18e0b4b4403fa5389267fe9343f7473.jpg_80x80q80.jpg_.webp'
          }
        ],
        imgListWith: '500px',
        page: 1,
        imgUrl: process.env.VUE_APP_IMG_URL,
        previewImg: ''
      }
    },
    mounted() {
      this.imageList = this.imgList
      this.clickHandler(this.imageIndex)
      this.imgListWith = this.imageList.length * 60 - 10 + 'px'
    },
    methods: {
      clickHandler(index) {
        this.imageIndex = index
        this.previewImg = JLTools.formatUrl(this.imageList[this.imageIndex].path)
      },
	  showClickSpecsImage(url) {
        if (url) {
          this.previewImg = JLTools.formatUrl(url)
        }
      },
      prev() {
        this.page = 1
      },
      next() {
        if (this.imageList.length >= 5) {
          this.page = 2
        }
      },
      previewStart() {
        this.$refs.scan_preview.style.display = 'block'
        this.$refs.enlarge_div.style.display = 'block'
      },
      previewEnd() {
        this.$refs.scan_preview.style.display = 'none'
        this.$refs.enlarge_div.style.display = 'none'
      },
      preview(e) {
        let offsetX = e.offsetX // 鼠标x坐标
        let offsetY = e.offsetY // 鼠标y坐标

        let image_width = this.$refs.main_image.offsetWidth // 图片 宽度
        let image_height = this.$refs.main_image.offsetHeight // 图片 高度

        let preview_width = this.$refs.scan_preview.offsetWidth // 扫描框 宽度
        let preview_height = this.$refs.scan_preview.offsetHeight // 扫描框 高度

        let offsetLeft = offsetX - preview_width / 2 // 距离图片容器左边
        let offsetTop = offsetY - preview_height / 2

        // 控制扫描框所在的范围
        if (offsetLeft < 0) offsetLeft = 0
        if (offsetTop < 0) offsetTop = 0

        if (offsetLeft >= image_width - preview_width) offsetLeft = image_width - preview_width
        if (offsetTop >= image_height - preview_height) offsetTop = image_height - preview_height

        // 让扫描框跟随鼠标偏移
        this.$refs.scan_preview.style.transform = `translate(${offsetLeft}px,${offsetTop}px)`

        // 图片放大预览开始
        const enlarge_div_width = this.$refs.enlarge_div.offsetWidth
        const enlarge_div_height = this.$refs.enlarge_div.offsetHeight

        const widthRatio = (preview_width / image_width).toFixed(2) * 1
        const heightRatio = (preview_height / image_height).toFixed(2) * 1
        const topRatio = (offsetTop / image_height).toFixed(2) * 1
        const leftRatio = (offsetLeft / image_height).toFixed(2) * 1
        this.$refs.enlarge.style.width = (enlarge_div_width / widthRatio).toFixed(2) * 1 + 'px'
        this.$refs.enlarge.style.height = (enlarge_div_height / heightRatio).toFixed(2) * 1 + 'px'

        const imageTop = (this.$refs.enlarge.offsetHeight * topRatio).toFixed(2) * 1
        const imageLeft = (this.$refs.enlarge.offsetWidth * leftRatio).toFixed(2) * 1

        this.$refs.enlarge.style.transform = `translate(-${imageLeft}px,-${imageTop}px)`
      }
    }
  }
</script>

<style scoped lang="less">
  .view {
    > .top {
      height: 348px;
      width: 348px;
      position: relative;
      cursor: crosshair;

      > img {
        height: 100%;
        width: 100%;
      }

      > .scan_preview {
        display: none;
        position: absolute;
        pointer-events: none;
        top: 0;
        height: 200px;
        width: 200px;
        background-color: rgba(178, 190, 131, 0.5);
        border: 1px #bab3b4 solid;
      }

      > .enlarge {
        display: none;
        position: absolute;
        height: 500px;
        width: 500px;
        left: calc(100% + 10px);
        top: 0;
        background-color: #fff;
        border: 1px #bab3b4 solid;
        overflow: hidden;
        z-index: 999;

        > img {
          max-width: 200%;
          max-height: 200%;
        }
      }
    }

    > .bottom {
      margin-top: 10px;
      display: flex;
      align-items: center;

      > .scroll {
        width: 250px;
        overflow: hidden;
        flex: 1;

        > .image_list {
          > img {
            height: 50px;
            width: 50px;
            margin-left: 10px;
            border: 1px solid #9d9ea1 !important;
            border-radius: 4px;
            &.selected {
              border: 1px solid #f57224;
            }
          }
          > .xuan {
            border: 1px solid var(--Zcolor) !important;
          }
          > img:first-child {
            margin-left: 0px;
          }
          > img:hover {
            cursor: pointer;
            border: 2px solid var(--Zcolor) !important;
          }
        }
      }

      > .btn {
        > .iconfont {
          font-size: 26px;
          cursor: pointer;
        }
      }
      > .btn_disable {
        > .iconfont {
          font-size: 26px;
          /*cursor: pointer;*/
          color: #e3e4e5;
        }
      }
    }
  }
  .page2 {
    margin-left: -240px;
    transition: margin-left 0.5s;
  }
  .page1 {
    margin-left: 3px;
    transition: margin-left 0.5s;
  }
</style>
