<script setup>
  import { useRoute, useRouter } from 'vue-router'
  import { watch } from 'vue'
  import useStore from '@/store/pinia'
  const { userStore, HsystemStore } = useStore()

  const router = useRouter()
  const route = useRoute()

  const list = []

  const to = (data, i) => {
    if (data && data.name && data.name === 'blankPage') {
      router.currentRoute.value.params = {}
      router.push({
        name: 'productList',
        query: data.params
      })
    }
  }
</script>

<template>
  <div class="breadcrumb">
    <div class="item" v-for="(item, i) of HsystemStore.breadcrumbList" :key="i">
      <div @click="to(item.data, i)">{{ item.name }}</div>
      <i class="iconfont icon-xiangyou1"></i>
    </div>
  </div>
</template>

<style scoped lang="less">
  .breadcrumb {
    /*background-color: #e3e4e5;*/
    color: #6f6f6f;
    display: flex;
    align-items: center;

    > .item {
      display: flex;
      align-items: center;
      height: 40px;

      > div {
        cursor: pointer;

        &:hover {
          text-decoration: underline;
        }
      }

      > i {
        margin-left: 15px;
        margin-right: 15px;
      }

      &:last-child {
        > i {
          display: none;
        }

        > div {
          &:hover {
            text-decoration: none;
          }
        }
      }
    }
  }
</style>
