<script setup>
  import Page from '@/components/Page'
  import Breadcrumb from '@/components/Breadcrumb'
  import productDetails from './compontent/product-details'
  import productDetailsLeft from './compontent/product-details-left'
  import productPolicy from './compontent/product-policy'
  import popularProducts from './compontent/popular-products'
  import specificationList from './compontent/specification-list'
  import reviewList from './compontent/review-list'
  import { getCurrentInstance, onMounted, ref, reactive, defineEmits, nextTick, watchEffect, provide } from 'vue'
  import JLTools from '@/util/JLTools'
  import api from '@/api'
  import { useRouter, useRoute } from 'vue-router'
  import useStore from '@/store/pinia'
  const { userStore } = useStore()
  const router = useRouter()
  const { proxy } = getCurrentInstance()

  const tabItems = [{ label: 'Features' }, { label: 'Specifications' }, { label: 'Reviews' }]

  const productDetailsLeftRef = ref({})
  const productDetailsRef = ref({})
  const popularProductsRef = ref({})
  const reviewListRef = ref({})

  const data = reactive({
    loading: false,
    productId: '', // 商品id
    productData: {}, // 商品图片集合
    recordsProductInfo: {}, // 商品评论信息
    baseIndex: 1
  })

  // 获取商品详情
  const getProductDetails = () => {
    data.loading = true
    api.product
      .getProductDetails(data.productId)
      .then((res) => {
        console.log('查询商品详细', res)
        if (JLTools.isNull(res) || JLTools.isNull(res.id)) return
        data.productData = res
        nextTick(() => {
          productDetailsRef.value.handleProductData()
          productDetailsLeftRef.value.getImgsList(data.productData.showImgs)
          popularProductsRef.value.getPickProduct()
        })
        data.loading = false
      })
      .catch(() => {
        data.loading = false
      })
  }

  // 获取评论信息
  const getCommentCount = () => {
    api.product
      .recordsProduct({
        productId: data.productId
      })
      .then((res) => {
        data.recordsProductInfo = res
        nextTick(() => {
          reviewListRef.value.handleCommentCount(res)
        })
      })
  }

  // 切换底部选项
  const toggles = (num) => {
    data.baseIndex = num
  }

  // 收藏取消收藏
  const collectionProduct = () => {
    if (!userStore.userInfo.userId) {
      return router.push({
        path: '/login'
      })
    }
    let params = {
      logicalDeleted: false,
      praised: false,
      product: data.productData,
      userId: userStore.userInfo.userId
    }
    data.loading = true
    params.collected = !data.productData.collected
    if (data.productData.collected) {
      params.id = data.productData.collectedId
      // console.log(params, 'params')
      api.product.productCollectPraises(params, 'put').then(async (res) => {
        data.productData.collected = res.collected
        data.productData.collectedId = res.id
        data.loading = false
        await getCollectProduct()
      })
    } else {
      // console.log(params, 'params')
      api.product.productCollectPraises(params).then(async (res) => {
        data.productData.collected = res.collected
        data.productData.collectedId = res.id
        data.loading = false
        getCollectProduct()
      })
    }
  }

  // 获取收藏列表
  const getCollectProduct = () => {
    if (JLTools.isNull(userStore.userInfo.userId)) return
    let params = {
      'collected.equals': true,
      'logicalDeleted.equals': false,
      'userId.equals': userStore.userInfo.userId,
      size: 1000
    }
    return api.product
      .getGeneralProduct(params)
      .then((res) => {
        // console.log(res, '收藏数量')
        if (res && res.length) {
          userStore.setCollectProduct(res.length)
        }
        return res
      })
      .catch((error) => {
        return []
      })
  }

  const init = () => {
    if (!data.productId) return
    getProductDetails()
    getCommentCount()
  }

  onMounted(() => {
    data.productId = useRoute().params.id || ''
    init()
  })

  watchEffect(() => {
    provide('recordsProductInfo', data.recordsProductInfo)
  })
  provide('collectionProduct', collectionProduct)
</script>

<template>
  <div class="content">
    <Page>
      <Breadcrumb />
      <div class="pro-main rowSS" v-loading="data.loading" attr-data="商品详情展示">
        <div class="pro-main-left">
          <product-details-left ref="productDetailsLeftRef" :productData="data.productData" />
        </div>
        <div class="pro-main-right">
          <product-details ref="productDetailsRef" :productData="data.productData" :recordsProductInfo="data.recordsProductInfo"></product-details>
        </div>
        <product-policy :productData="data.productData"></product-policy>
      </div>

      <div class="pro-base-box rowBS" attr-data="底部">
        <div class="pro-left" attr-data="商品相似推荐">
          <popular-products ref="popularProductsRef" :productId="data.productId" :productInfo="data.productData" />
        </div>
        <div class="pro-right">
          <div class="pro-right-title">
            <div v-for="(item, index) in tabItems" :key="index" class="item" @click="toggles(index + 1)" :class="{ xuan: data.baseIndex === index + 1 }">
              <span>{{ $t(item.label) }}</span>
            </div>
          </div>

          <div class="pro-right-main rowSC OVERVIEW" v-show="data.baseIndex === 1">
            <div class="content-rich-box mt20" v-if="data.productData.contentRich" v-html="data.productData.contentRich"></div>
          </div>

          <!-- 规范列表 -->
          <div class="pro-right-main" v-show="data.baseIndex === 2">
            <specification-list ref="specificationListRef" :productId="data.productId" />
          </div>

          <!-- 评论 -->
          <div class="pro-right-main" v-show="data.baseIndex === 3">
            <reviewList ref="reviewListRef" :productId="data.productId" />
          </div>
        </div>
      </div>
    </Page>
  </div>
</template>

<style lang="less" scoped>
  .pro-main {
    width: 100%;
    background: #fff;
    .pro-main-left {
      width: 348px;
    }
  }

  .pro-base-box {
    width: 100%;
    margin: 20px 0;
    .pro-left {
      width: 180px;
      background: #fff;
    }
    .pro-right {
      float: right;
      width: calc(100% - 300px);
      background: #fff;
    }
  }

  .pro-right {
    float: right;
    width: calc(100% - 300px);
    background: #fff;
    .pro-right-title {
      width: 100%;
      height: 38px;
      border-bottom: 1px solid #DCDFE6;
      .item {
        float: left;
        line-height: 20px;
        font-weight: 500;
        font-size: 14px;
        position: relative;
        margin-right: 25px;
        text-transform: uppercase;
        color: #909399;
        &:hover {
          cursor: pointer;
          color: var(--Zcolor);
        }
      }
      .xuan {
        border-bottom: 2px solid var(--Zcolor);
        height: 38px;
        color: var(--Zcolor);
      }
    }
  }

  .pro-right-main {
    width: 100%;
    min-height: 400px;
    background: #fff;
    .content-rich-box {
      width: 800px;
      margin: 16px;
    }
  }
</style>
